var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "rootVM" } }, [
    _vm._m(0),
    _c("div", { staticClass: "panel panel-flat" }, [
      _c(
        "div",
        {
          staticClass: "panel-body",
          staticStyle: { width: "1300px", margin: "20px 0px 0px auto" }
        },
        [
          _c(
            "form",
            {
              attrs: { action: "#", search: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.search.apply(null, arguments)
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.search.apply(null, arguments)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-3", staticStyle: { width: "500px" } },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.detailsFieldMap.noticeTitle))
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchVM.noticeTitle,
                            expression: "searchVM.noticeTitle"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.detailsFieldMap.noticeTitle
                        },
                        domProps: { value: _vm.searchVM.noticeTitle },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.searchVM,
                              "noticeTitle",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3", staticStyle: { width: "500px" } },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.detailsFieldMap.noticeCont))
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchVM.noticeCont,
                            expression: "searchVM.noticeCont"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: _vm.detailsFieldMap.noticeCont
                        },
                        domProps: { value: _vm.searchVM.noticeCont },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.searchVM,
                              "noticeCont",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]
                ),
                _vm._m(1)
              ])
            ]
          )
        ]
      )
    ]),
    _c(
      "div",
      [
        _c("div", { staticClass: "mb-10 text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-labeled bg-primary",
              attrs: { type: "button" },
              on: { click: _vm.startCreate }
            },
            [_vm._m(2), _vm._v(" 추가 ")]
          )
        ]),
        _c("KendoGrid", {
          ref: "grid",
          attrs: {
            "auto-bind": true,
            "api-url": _vm.apiUrl.pageListApi,
            columns: _vm.gridColumns,
            "apply-search-condition": _vm.applySearchStateOnGridLoad
          },
          on: { "selected-row-item-changed": _vm.selectedRowItemChanged }
        }),
        _c("div", { staticClass: "mt-10 mb-15 text-right" })
      ],
      1
    ),
    _c(
      "form",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isEditMode || _vm.isCreateMode,
            expression: "isEditMode || isCreateMode"
          }
        ],
        staticClass: "form-horizontal form-validate-jquery",
        attrs: { id: "detailsForm", action: "#" }
      },
      [
        _c("div", { staticClass: "panel panel-flat" }, [
          _c("div", { staticClass: "panel-heading" }, [
            _vm._m(3),
            _c("div", { staticClass: "heading-elements" }, [
              _c("ul", { staticClass: "icons-list" }, [
                _c("li", [
                  _c("a", {
                    attrs: { "data-action": "collapse" },
                    on: { click: _vm.pannelHidden }
                  })
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "panel-body panel-body-center" }, [
            _c(
              "div",
              {
                staticClass: "row panel-detail",
                staticStyle: { width: "1400px" }
              },
              [
                _c("fieldset", [
                  _vm._m(4),
                  _c("div", { staticClass: "col-lg-12 inputDiv" }, [
                    _c("div", { staticClass: "row col-lg-12" }, [
                      _c(
                        "div",
                        { staticClass: "col-lg-6" },
                        [
                          _c("jarvis-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value:
                                  _vm.validationRule.detailsItem.noticeTitle,
                                expression:
                                  "validationRule.detailsItem.noticeTitle"
                              }
                            ],
                            attrs: {
                              label: _vm.detailsFieldMap.noticeTitle,
                              required: "true",
                              field: "detailsItem.noticeTitle",
                              "data-vv-name": "detailsItem.noticeTitle"
                            },
                            model: {
                              value: _vm.detailsItem.noticeTitle,
                              callback: function($$v) {
                                _vm.$set(_vm.detailsItem, "noticeTitle", $$v)
                              },
                              expression: "detailsItem.noticeTitle"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-lg-4 form-group" }, [
                        _c("label", { staticClass: "col-lg-3 control-label" }, [
                          _vm._v(_vm._s(_vm.detailsFieldMap.oriFileNm) + " :")
                        ]),
                        _vm.isCreateMode || _vm.isEditMode
                          ? _c(
                              "div",
                              { staticStyle: { "margin-bottom": "10px" } },
                              [
                                _c("div", { staticClass: "upl_span" }, [
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.isCreateMode || _vm.isUpdateFile,
                                        expression:
                                          "isCreateMode || isUpdateFile"
                                      }
                                    ],
                                    ref: "uplFileName"
                                  }),
                                  _vm.isEditMode && !_vm.isUpdateFile
                                    ? _c(
                                        "a",
                                        {
                                          attrs: { fileDownloadBtn: "" },
                                          on: { click: _vm.fileDownload }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.detailsItem.oriFileNm
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]),
                                _c("span", { key: _vm.keyForFileButtons }, [
                                  _c("input", {
                                    ref: "reqFile",
                                    staticClass: "hidden",
                                    attrs: { type: "file" },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleFileUpload()
                                      }
                                    }
                                  }),
                                  _vm.isFileUploaded == false
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          staticStyle: {
                                            width: "20%",
                                            float: "right",
                                            "margin-right": "11px"
                                          },
                                          on: { click: _vm.clickFileBtn }
                                        },
                                        [_vm._v(" 파일첨부 ")]
                                      )
                                    : _vm._e(),
                                  _vm.isFileUploaded
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-secondary",
                                          staticStyle: {
                                            width: "20%",
                                            float: "right",
                                            "margin-right": "11px"
                                          },
                                          on: { click: _vm.deleteAttachedFile }
                                        },
                                        [_vm._v(" 파일삭제 ")]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm.isEditMode
                        ? _c("div", { staticClass: "col-lg-2" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-md-4 title",
                                staticStyle: { "margin-top": "8px" },
                                attrs: { align: "right" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.detailsFieldMap.noticeId) + ":"
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "row col-md-8 form-group" },
                              [
                                _c("input", {
                                  staticClass: "form-control",
                                  staticStyle: {
                                    width: "70px",
                                    "text-align": "right"
                                  },
                                  attrs: { type: "text", disabled: true },
                                  domProps: { value: _vm.detailsItem.noticeId }
                                })
                              ]
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      { staticClass: "row col-lg-12 form-group" },
                      [
                        _c("ckeditor", {
                          attrs: { config: _vm.editorConfig },
                          model: {
                            value: _vm.detailsItem.noticeCont,
                            callback: function($$v) {
                              _vm.$set(_vm.detailsItem, "noticeCont", $$v)
                            },
                            expression: "detailsItem.noticeCont"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.isEditMode
                    ? _c("div", { staticClass: "col-lg-12" }, [
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-md-4 title",
                              staticStyle: { "margin-top": "8px" },
                              attrs: { align: "right" }
                            },
                            [_vm._v(_vm._s(_vm.detailsFieldMap.regDtm) + ":")]
                          ),
                          _c(
                            "div",
                            { staticClass: "row col-md-8 form-group" },
                            [
                              _c("input", {
                                staticClass: "form-control",
                                staticStyle: { width: "200px" },
                                attrs: { type: "text", disabled: true },
                                domProps: {
                                  value: _vm._f("toDisplayDateTime")(
                                    _vm.detailsItem.regDtm
                                  )
                                }
                              })
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-md-4 title",
                              staticStyle: { "margin-top": "8px" },
                              attrs: { align: "right" }
                            },
                            [_vm._v(_vm._s(_vm.detailsFieldMap.regUser) + ":")]
                          ),
                          _c(
                            "div",
                            { staticClass: "row col-md-8 form-group" },
                            [
                              _c("input", {
                                staticClass: "form-control",
                                staticStyle: { width: "200px" },
                                attrs: { type: "text", disabled: true },
                                domProps: { value: _vm.detailsItem.regUser }
                              })
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-md-4 title",
                              staticStyle: { "margin-top": "8px" },
                              attrs: { align: "right" }
                            },
                            [_vm._v(_vm._s(_vm.detailsFieldMap.updDtm) + ":")]
                          ),
                          _c(
                            "div",
                            { staticClass: "row col-md-8 form-group" },
                            [
                              _c("input", {
                                staticClass: "form-control",
                                staticStyle: { width: "200px" },
                                attrs: { type: "text", disabled: true },
                                domProps: {
                                  value: _vm._f("toDisplayDateTime")(
                                    _vm.detailsItem.updDtm
                                  )
                                }
                              })
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "col-lg-3" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-md-4 title",
                              staticStyle: { "margin-top": "8px" },
                              attrs: { align: "right" }
                            },
                            [_vm._v(_vm._s(_vm.detailsFieldMap.updUser) + ":")]
                          ),
                          _c(
                            "div",
                            { staticClass: "row col-md-8 form-group" },
                            [
                              _c("input", {
                                staticClass: "form-control",
                                staticStyle: { width: "200px" },
                                attrs: { type: "text", disabled: true },
                                domProps: { value: _vm.detailsItem.updUser }
                              })
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              ]
            ),
            !_vm.isEditMode
              ? _c("div", { staticClass: "text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-labeled mx-sm-1",
                      attrs: { type: "button" },
                      on: { click: _vm.closeDetails }
                    },
                    [_vm._m(5), _vm._v(" 닫기 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-labeled bg-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.createData }
                    },
                    [_vm._m(6), _vm._v(" 저장 ")]
                  )
                ])
              : _vm._e(),
            _vm.isEditMode
              ? _c("div", { staticClass: "text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-labeled mx-sm-1",
                      attrs: { type: "button" },
                      on: { click: _vm.closeDetails }
                    },
                    [_vm._m(7), _vm._v(" 닫기 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-labeled bg-warning mx-sm-1",
                      attrs: { type: "button" },
                      on: { click: _vm.deleteData }
                    },
                    [_vm._m(8), _vm._v(" 삭제 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-labeled bg-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.updateData }
                    },
                    [_vm._m(9), _vm._v(" 저장 ")]
                  )
                ])
              : _vm._e(),
            _vm.debug
              ? _c("span", [
                  _vm._v(" isCreateMode: " + _vm._s(_vm.isCreateMode) + " "),
                  _c("br"),
                  _vm._v(" isEditMode: " + _vm._s(_vm.isEditMode) + " "),
                  _c("br"),
                  _vm._v(
                    " detailsItemOriginal: " +
                      _vm._s(_vm.detailsItemOriginal) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(" detailsItem: " + _vm._s(_vm.detailsItem) + " "),
                  _c("br"),
                  _vm._v(" errors: " + _vm._s(_vm.errors) + " "),
                  _c("br")
                ])
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "pageTitle" } }, [
      _c("b", [_vm._v("공지사항")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mb-10 text-right", staticStyle: { float: "right" } },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-labeled bg-primary",
            staticStyle: { float: "right" },
            attrs: { type: "submit" }
          },
          [
            _c("b", [_c("i", { staticClass: "icon-search4" })]),
            _vm._v(" 검색 ")
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-plus3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "panel-title" }, [
      _c("i", { staticClass: "icon-checkmark3 position-left" }),
      _c("b", [_vm._v("상세정보")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "text-semibold col-md-12" }, [
      _c("i", { staticClass: "icon-cog3 position-left" }),
      _vm._v(" 공지사항 정보 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-cross" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-checkmark3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-cross" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-minus3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-checkmark3" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }