<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>공지사항</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="width: 1300px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search" @keyup.enter.prevent="search" search>
					<div class="row">
						<div class="col-md-3" style="width: 500px">
							<div class="form-group">
								<label>{{ detailsFieldMap.noticeTitle }}</label>
								<input type="text" class="form-control" :placeholder="detailsFieldMap.noticeTitle" v-model="searchVM.noticeTitle" />
							</div>
						</div>
						<div class="col-md-3" style="width: 500px">
							<div class="form-group">
								<label>{{ detailsFieldMap.noticeCont }}</label>
								<input type="text" class="form-control" :placeholder="detailsFieldMap.noticeCont" v-model="searchVM.noticeCont" />
							</div>
						</div>
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary" style="float: right">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>
			<KendoGrid
				ref="grid"
				:auto-bind="true"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>
		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body panel-body-center">
					<div class="row panel-detail" style="width: 1400px">
						<fieldset>
							<legend class="text-semibold col-md-12">
								<i class="icon-cog3 position-left"></i>
								공지사항 정보
							</legend>
							<div class="col-lg-12 inputDiv">
								<div class="row col-lg-12">
									<div class="col-lg-6">
										<!-- 공지사항 제목. 필수 -->
										<jarvis-field
											:label="detailsFieldMap.noticeTitle"
											required="true"
											field="detailsItem.noticeTitle"
											v-model="detailsItem.noticeTitle"
											data-vv-name="detailsItem.noticeTitle"
											v-validate="validationRule.detailsItem.noticeTitle"
										></jarvis-field>
									</div>
									<div class="col-lg-4 form-group">
										<!-- 첨부파일명 -->
										<!-- <div class="form-group" style="display: inline-block"> -->
										<label class="col-lg-3 control-label">{{ detailsFieldMap.oriFileNm }} :</label>

										<div style="margin-bottom: 10px" v-if="isCreateMode || isEditMode">
											<div class="upl_span">
												<span ref="uplFileName" v-show="isCreateMode || isUpdateFile"></span>
												<a fileDownloadBtn @click="fileDownload" v-if="isEditMode && !isUpdateFile">
													{{ detailsItem.oriFileNm }}
												</a>
											</div>
											<span :key="keyForFileButtons">
												<input type="file" class="hidden" v-on:change="handleFileUpload()" ref="reqFile" />
												<button
													class="btn btn-primary"
													style="width: 20%; float: right; margin-right: 11px"
													@click="clickFileBtn"
													v-if="isFileUploaded == false"
												>
													파일첨부
												</button>
												<button
													class="btn btn-secondary"
													style="width: 20%; float: right; margin-right: 11px"
													@click="deleteAttachedFile"
													v-if="isFileUploaded"
												>
													파일삭제
												</button>
											</span>
										</div>
										<!-- </div> -->
									</div>
									<div class="col-lg-2" v-if="isEditMode">
										<!-- 공지사항 ID-->
										<label class="col-md-4 title" align="right" style="margin-top: 8px">{{ detailsFieldMap.noticeId }}:</label>
										<div class="row col-md-8 form-group">
											<input type="text" class="form-control" style="width: 70px; text-align: right" :value="detailsItem.noticeId" :disabled="true" />
										</div>
									</div>
								</div>
								<!-- 공지사항 내용. -->
								<div class="row col-lg-12 form-group">
									<ckeditor v-model="detailsItem.noticeCont" :config="editorConfig"></ckeditor>
								</div>
							</div>
							<div class="col-lg-12" v-if="isEditMode">
								<div class="col-lg-3">
									<!-- 등록일, 수정모드만 표시 -->
									<label class="col-md-4 title" align="right" style="margin-top: 8px">{{ detailsFieldMap.regDtm }}:</label>
									<div class="row col-md-8 form-group">
										<input type="text" class="form-control" style="width: 200px" :value="detailsItem.regDtm | toDisplayDateTime" :disabled="true" />
									</div>
								</div>
								<div class="col-lg-3">
									<!-- 등록자, 수정모드만 표시 -->
									<label class="col-md-4 title" align="right" style="margin-top: 8px">{{ detailsFieldMap.regUser }}:</label>
									<div class="row col-md-8 form-group">
										<input type="text" class="form-control" style="width: 200px" :value="detailsItem.regUser" :disabled="true" />
									</div>
								</div>
								<div class="col-lg-3">
									<!-- 수정일, 수정모드만 표시 -->
									<label class="col-md-4 title" align="right" style="margin-top: 8px">{{ detailsFieldMap.updDtm }}:</label>
									<div class="row col-md-8 form-group">
										<input type="text" class="form-control" style="width: 200px" :value="detailsItem.updDtm | toDisplayDateTime" :disabled="true" />
									</div>
								</div>
								<div class="col-lg-3">
									<!-- 수정자, 수정모드만 표시 -->
									<label class="col-md-4 title" align="right" style="margin-top: 8px">{{ detailsFieldMap.updUser }}:</label>
									<div class="row col-md-8 form-group">
										<input type="text" class="form-control" style="width: 200px" :value="detailsItem.updUser" :disabled="true" />
									</div>
								</div>
							</div>
						</fieldset>
					</div>
					<div class="text-right" v-if="!isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="createData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
					<div class="text-right" v-if="isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
							<b><i class="icon-minus3"></i></b>
							삭제
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="updateData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import JarvidField from '@/components/JarvisField';
import extend from 'extend';
import moment from 'moment';
import _ from 'lodash';
import apiIndex from '../../api/index';

const noticeUrl = apiIndex.notice;

let axiosExtention;

export default {
	components: {
		KendoGrid,
		'jarvis-field': JarvidField,
	},
	data: () => ({
		pageParam: null,
		apiUrl: {
			excelApi: '/system/commcodemng/excel',
			pageListApi: noticeUrl.inqNotice,
			detailsApi: '/system/commcodemng/api/details',
		},
		searchVM: {},
		// 상세 select options
		detailsOptions: {},
		// 검색 select options
		searchOptions: {},
		// 적용된 검색조건
		searchState: {},
		gridColumns: [
			{ field: 'noticeId', title: '순번', align: 'right', sortable: false, width: '7%' },
			{ field: 'noticeTitle', title: '제목', width: '45%', overFlowHidden: true },
			{ field: 'oriFileNm', title: '첨부파일명', overFlowHidden: true },
			{
				field: 'views',
				title: '조회수',
				align: 'right',
				width: '7%',
			},
			{
				field: 'regDtm',
				title: '등록일',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
				width: '13%',
			},
			{ field: 'regUser', title: '등록자', width: '10%' },
		],
		detailsFieldMap: {
			noticeId: '순번',
			noticeTitle: '제목',
			noticeCont: '내용',
			oriFileNm: '첨부파일명',
			saveFileNm: '첨부파일명',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '수정일',
			updUser: '수정자',
		},
		detailsValidationRule: {
			common: {
				// 공통
				noticeId: 'required|max:8',
				noticeTitle: 'required',
			},
			edit: {
				// 수정
			},
			create: {
				// 생성
			},
		},
		// 상세편집 원본
		detailsItemOriginal: {},
		// 상세편집 수정본
		detailsItem: {},
		// 첨부파일 정보
		detailsUploadItem: {},
		isUpdateFile: false,
		editorConfig: {
			toolbar: [
				{ name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', 'Underline'] },
				{ name: 'undo', items: ['Undo', 'Redo'] },
				{ name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
				{ name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
			],
			height: '400px',
		},
		keyForFileButtons: 0,
		isFileUploaded: false,
	}),
	created() {
		//검색VM
		this.searchVM = {
			noticeTitle: '',
			noticeCont: '',
			oriFileNm: '',
		};

		//상세 select options
		this.detailsOptions = {};

		this.searchOptions = {};

		_.extend(this.detailsValidationRule.edit, this.detailsValidationRule.common);
		_.extend(this.detailsValidationRule.create, this.detailsValidationRule.common);

		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });

		axiosExtention = this.$jarvisExtention.axiosExtention;
	},
	mounted() {
		this.search();
	},
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			return {
				detailsItem: this.isEditMode ? this.detailsValidationRule.edit : this.detailsValidationRule.create,
			};
		},
		isDisabled() {
			return false;
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); // Validation상태 초기화
			// details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal))
				this.$nextTick(function () {
					window.scrollTo({ top: 700, behavior: 'smooth' });
				});
		},
		period() {
			if (this.period === 'today' || this.period === 'delay') {
				this.isPeriodDisabled = true;
				// 값은 사용되지 않는다, 보여주기용
				this.searchVM.periodStrtDt = moment().format('YYYY-MM-DD');
				this.searchVM.periodEndDt = moment().format('YYYY-MM-DD');
				this.searchVM.accdtPrvtnPgssCds = ['01', '02', '03'];
			} else {
				this.isPeriodDisabled = false;
				this.searchVM.periodStrtDt = '';
				this.searchVM.periodEndDt = '';
				this.searchVM.accdtPrvtnPgssCds = ['01', '02', '03', '04'];
			}
		},
		isFileUploaded(newVal) {
			this.keyForFileButtons += 1;
			if (newVal == false) {
				this.$nextTick(function () {
					this.$refs.uplFileName.innerText = '';
					this.$refs.reqFile.value = '';
				});
			}
		},
		// isCreateMode() {
		// 	const element = $('.inputDiv')[0];
		// 	if (this.isCreateMode) {
		// 		element.classList.add('col-lg-offset-2');
		// 	} else {
		// 		element.classList.remove('col-lg-offset-2');
		// 	}
		// },
	},
	methods: {
		// data pannel hidden toggle
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return extend(true /*deep*/, param, this.searchState || {});
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			this.isUpdateFile = false;
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				// this.selectOne(selectedRowItem, false);
				// this.histroyLoad(selectedRowItem);
				this.$axios
					.post(noticeUrl.inqOneNotice, {
						noticeId: selectedRowItem.noticeId,
						regUser: selectedRowItem.regUser,
					})
					.then(
						function (response) {
							this.detailsItemOriginal = $.extend(true /*deep*/, {}, response.data);
							this.detailsItem = $.extend(true /*deep*/, {}, response.data);
							if (this.detailsItem != null && this.detailsItem.saveFileNm != null) {
								this.isFileUploaded = true;
							} else {
								this.isFileUploaded = false;
							}
							this.$refs.grid.load();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} // else {
			// 	//상세화면 닫기 (다시로드등으로 선택변경)
			// this.closeDetails();
			// }
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		//데이터 추가 시작
		startCreate() {
			this.detailsItemOriginal = {};
			this.detailsItem = {
				noticeId: '',
				noticeTitle: '',
				noticeCont: '',
				oriFileNm: '',
				oriFileNmBeforeUpdate: '',
			};
			this.detailsUploadItem = {};
			this.isFileUploaded = false;

			this.$nextTick(function () {
				this.$refs.uplFileName.innerText = '';
				this.$refs.reqFile.value = '';
			});
			// 데이터를 새로 등록할 때, 이전 등록 때와 파일의 변경사항이 없는 경우(같은 파일 업로드) 업로드 되지 않는 것을 방지하기 위해 데이터를 리셋
		},
		/*
		 * 파일 업로드 컨트롤
		 */
		// 파일첨부 버튼을 누르면 type=file element를 클릭하도록 우회 처리
		clickFileBtn(e) {
			e.preventDefault();
			this.$refs.reqFile.click();
		},
		// 파일이 첨부되었을 때 처리
		handleFileUpload() {
			const file = this.$refs.reqFile.files[0];
			this.detailsUploadItem.uploadFile = file;
			this.detailsUploadItem.oriFileNm = file.name;
			this.detailsUploadItem.fileSize = file.size;
			if (this.detailsItem.oriFileNm != this.detailsUploadItem) {
				this.isUpdateFile = true;
				this.detailsItem.oriFileNmBeforeUpdate = this.detailsItem.oriFileNm;
			}
			this.isFileUploaded = true;
			this.uploadTemp(0, this.detailsUploadItem.oriFileNm);
		},
		// temp 파일 분할 업로드
		uploadTemp(offset, fileName) {
			var file = this.detailsUploadItem.uploadFile;
			if (fileName && fileName != this.detailsUploadItem.oriFileNm) this.detailsUploadItem.saveFileNm = fileName;
			// max upload file size
			const maxFileSize = process.env.VUE_APP_MAX_FILE_SIZE;
			let fileSize = this.detailsUploadItem.fileSize;
			if (fileSize > maxFileSize) return alert('첨부파일은 300MB 까지 업로드 가능합니다.');

			if (offset >= file.size) {
				// 업로드 완료 후처리
				this.$refs.uplFileName.innerText = this.detailsUploadItem.oriFileNm;
				this.$refs.uplFileName.setAttribute('title', this.detailsUploadItem.oriFileNm);
				this.detailsItem.oriFileNm = this.detailsUploadItem.oriFileNm;
			} else {
				var size = Math.min(offset + 1000000, file.size);
				var formData = new FormData();
				formData.append('noticeId', this.detailsItem.noticeId);
				formData.append('fileName', fileName);
				formData.append('fileSize', fileSize);
				formData.append('uploadFile', file.slice(offset, size));
				this.$axios
					.post(noticeUrl.uplNotice, formData, {
						headers: { 'Content-Type': 'multipart/form-data' },
					})
					.then(
						function (r) {
							this.uploadTemp(size, r.data);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
		deleteAttachedFile() {
			this.detailsUploadItem = {};
			let tempFileNm = this.detailsItem.saveFileNm;
			if (tempFileNm != undefined && tempFileNm != null) {
				this.detailsItem.deleteFile = tempFileNm;
			}
			this.detailsItem.oriFileNm = null;
			this.detailsItem.saveFileNm = null;
			this.isFileUploaded = false;
		},
		//신규저장
		createData(e) {
			e.preventDefault();
			const closure = this;
			this.$validator.validateAll().then(function (success) {
				if (success == false) {
					return;
				}

				let param = {};

				Object.assign(param, closure.detailsItem, closure.detailsUploadItem);

				closure.$axios
					.post(noticeUrl.insNotice, param)
					.then(
						function () {
							closure.closeDetails();
							closure.searchState = extend(true /*deep*/, {}, closure.searchVM);
							closure.loadGrid();
							window.scrollTo({ top: 50, behavior: 'smooth' });
						}.bind(closure),
					)
					.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
			});
		},
		//수정저장
		updateData() {
			if (confirm('수정한 데이터를 저장하시겠습니까?')) {
				let param = {};
				Object.assign(param, this.detailsItem, this.detailsUploadItem);
				this.$axios
					// .post(noticeUrl.updNotice, this.detailsItem)
					.post(noticeUrl.updNotice, param)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			this.$axios
				.post(noticeUrl.delNotice, {
					noticeId: this.detailsItem.noticeId,
					saveFileNm: this.detailsItem.saveFileNm, // 현재 세이브 되어있는 것
					deleteFile: this.detailsItem.deleteFile, // 이전에 지웠던 것 -- 둘 중 하나가 있을 수 있음
				})
				.then(
					function () {
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		// 검색조건의 select box 값이 변경되면
		getValue(value) {
			this.period = value;
		},
		fileDownload() {
			// 첨부파일 다운로드
			let url = encodeURI(noticeUrl.fileNotice + this.detailsItem.saveFileNm);
			this.$axios({
				url: url,
				method: 'POST',
				responseType: 'blob', // important
			})
				.then(response => {
					let fileName = this.detailsItem.oriFileNm;
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
	},
};
</script>

<style>
#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}

fieldset {
	width: 100%;
}
#rootVM > .panel .panel-body .form-group label {
	width: 60%;
	margin-top: 5px;
	max-width: 130px;
	text-align: right;
}
.upl_span {
	display: inline-block;
	width: 45%;
	padding-top: 8px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.row .form-group .last {
	display: inline-block;
	width: 65% !important;
	margin-left: 15px;
}
.row .form-group > label {
	margin-top: 8px;
	margin-right: 0px;
}
.row:last-child .form-group > label {
	margin-right: 25px;
}
.row [period] {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.row [period] [type='date'] {
	width: 180px !important;
}
.row [period] > label {
	margin-top: 10px;
	margin-right: 15px !important;
}
#detailsForm .btn-wrapper .btn {
	margin-right: 5px;
}
#detailsForm .row:last-child .form-group > label {
	margin-right: 10px;
	width: 110px;
}
.table th {
	background: #eee;
	font-weight: bold;
	font-size: 1em;
}
.table th:first-child {
	width: 160px;
	text-align: center;
}
.table td {
	font-size: 1em;
}
.table td:first-child {
	text-align: center;
}
.table tbody tr:hover td {
	background: none repeat scroll 0 0 #bcc3d0;
	color: #000000;
}
[img] {
	clear: both;
	text-align: center;
	width: 100%;
}
.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}
</style>
